import React from "react"
import styled from "@emotion/styled"
import Margin from "../components/margin"
import SPBreak from "../components/SPBreak"
import { Link } from "gatsby"
import Subheading from "../components/subheading"
import MiddleHeading from "../components/middleHeading"
import SEO from "../components/SEO"

const Wrapper = styled.div`
  padding: 1em 1em 1em 3em;
  @media screen and (max-width: 460px) {
    padding: 0 0 0 1em;
  }
`

const Ununderline = styled.p`
  transform: scale(0.9,1.2);
  font-size: 1.2em;
  text-align: left;
  margin-bottom: 1.5em;
  margin-left: -2.2em;
  @media screen and (max-width: 460px) {
    margin-left: -.8em;
  }
`

const Paragraph = styled.p`
  text-indent: -.3em;
  line-height: 1.85em;
`

const Bigger = styled.span`
  font-size: 1.4em;
`

const Arrow = styled.span`
 color: ${props => props.theme.colors.highlight};
`

const IndexTemplate = () => {
  return (
    <>
      <SEO title="index" description="三野新・いぬのせなか座　写真／演劇プロジェクト「クバへ／クバから」／about はじめに → 参加方法 → お問い合わせ先 → 実行委員会 → 三野新 → いぬのせなか座 → サポートメンバー → 伴走者（役名未定） → パフォーマー（役名未定） → 入力された「役割」たち → " />
      <Wrapper>
        <Margin />
        <Ununderline>
          <MiddleHeading><span style={{ fontSize: '0.9em' }}>三野新・いぬのせなか座　<SPBreak />写真／演劇プロジェクト<SPBreak />「クバへ／クバから」</span></MiddleHeading>
        </Ununderline>
        <Margin />
        {" "}<br />
        <Paragraph>
          <Subheading><Link to="/app/about"><Bigger>about</Bigger></Link></Subheading><br />
          <Link to="/app/about/#はじめに">はじめに<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#参加方法">参加方法<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#お問い合わせ先">お問い合わせ先<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#実行委員会">実行委員会<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#三野新">　三野新<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#いぬのせなか座">　いぬのせなか座<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#サポートメンバー">　サポートメンバー<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#伴走者">　伴走者<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#パフォーマー（役名未定）">　パフォーマー（役名未定）<Arrow> → </Arrow></Link><br />
          <Link to="/app/about/#入力された「役割」たち">　入力された「役割」たち<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading>
            <Link to="/app/schedule">
              <Bigger>
                schedule
            </Bigger>
            </Link>
          </Subheading><br />
          <Link to="/app/schedule/#プロジェクト上演タイムライン">プロジェクト上演タイムライン<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading>
            <Bigger><Link to="/app/synopsis">synopsis</Link></Bigger>
          </Subheading><br />
          <Link to="/app/synopsis/#発端と目的">発端と目的<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#制作実行委員会のあり方">制作実行委員会のあり方<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#出発点となる「役割」">出発点となる「役割」<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#「プロジェクト上演」という形式">「プロジェクト上演」という形式<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#いぬのせなか座による介入">いぬのせなか座による介入<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#長期間にわたる「上演」とその運営">長期間にわたる「上演」とその運営<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#「クバ」とは">「クバ」とは<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#「撮影／上演」の困難さをめぐって">「撮影／上演」の困難さをめぐって<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#取材調査の方針">取材調査の方針<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#「座談会」の収録・公開">「座談会」の収録・公開<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#オンラインワークショップの開催">オンラインワークショップの開催<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#展覧会の開催、パフォーマンスの実施">展覧会の開催、パフォーマンスの実施<Arrow> → </Arrow></Link><br />
          <Link to="/app/synopsis/#「note」定期購読マガジンによるアーカイブ">「note」定期購読マガジンによるアーカイブ<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading><Link to="/app/statement"><Bigger>statement</Bigger></Link></Subheading><br />
          <Link to="/app/statement/#これは本当のことである、を再び引き受ける態度について">三野新「これは本当のことである、を再び引き受ける態度について」<Arrow> → </Arrow></Link><br />
          <Link to="/app/statement/#身振りと魂">ｈ「身振りと魂」<Arrow> → </Arrow></Link><br />
          <Link to="/app/statement/#上演のための7つのルール（Seven Rules for The Drama）">笠井康平「上演のための7つのルール（Seven Rules for The Drama）」<Arrow> → </Arrow></Link><br />
          <Link to="/app/statement/#目が眩むほどの倫理が">鈴木一平「目が眩むほどの倫理が」<Arrow> → </Arrow></Link><br />
          <Link to="/app/statement/#手がかり">なまけ「手がかり」<Arrow> → </Arrow></Link><br />
          <Link to="/app/statement/#所信と関心">山本浩貴「所信と関心」<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading><Bigger><Link to="/app/symposium">symposium</Link></Bigger></Subheading><br />
          <Link to="/app/symposium/#第1回「三野新の作歴とプロジェクト全体の基本構想をめぐって」">第1回「三野新の作歴とプロジェクト全体の基本構想をめぐって」<Arrow> → </Arrow></Link><br />
          <Link to="/app/symposium/#第2回「「クバへ／クバから」の写真群と沖縄写真史をめぐって」">第2回「「クバへ／クバから」の写真群と沖縄写真史をめぐって」<Arrow> → </Arrow></Link><br />
          <Link to="/app/symposium/#第3回「いぬのせなか座による応答」（仮）">第3回「いぬのせなか座による応答」（仮）<Arrow> → </Arrow></Link><br />
          <Link to="/app/symposium/#第4回・第5回「オンラインワークショップ」（仮）">第4回・第5回「オンラインワークショップ」（仮）<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading><Link to="/app/photobook"><Bigger>photo book</Bigger></Link></Subheading><br />
          <Link to="/app/photobook/#写真集について">写真集について<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        <Paragraph>
          <Subheading><Link to="/app/exhibition"><Bigger>exhibition</Bigger></Link></Subheading><br />
          <Link to="/app/exhibition/#展覧会・パフォーマンスについて">展覧会・パフォーマンスについて<Arrow> → </Arrow></Link><br />
        </Paragraph>
        <Margin />
        {" "} <br />
      </Wrapper>
    </>
  )
}

export default IndexTemplate
