import React from "react";
import styled from "@emotion/styled"

const Wrapper = styled.div`
  max-width: 650px;
  p {
    text-align: justify;
    text-justify: inter-ideograph;
    padding-left: 1em;
    padding-right: 2em;
    white-space: pre-wrap;
    @media screen and (max-width: 460px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  a {
    color: #b88fb6;
  }
`

export const BodyWrapper = (props) => (<Wrapper>{props.children}</Wrapper>)
