import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Heading from "../components/heading"
import { BodyWrapper } from "../components/bodyWrapper"
import MiddleHeading from "../components/middleHeading"
import Subheading from "../components/subheading"
import styled from "@emotion/styled"
import Margin from "../components/margin"
import MiddleMargin from "../components/middleMargin"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { options } from "../styles/richTextOptions"
import LowMargin from "../components/lowMargin"
import NoteCard from "../components/noteCard"
import { SYMPOSIUM1 } from "../constants/noteUrl"
import InfoBox from "../components/infoBox"
import InternalLink from "../components/internalLink"
import SubMiddleHeading from "../components/subMiddleHeading"
import SEO from "../components/SEO"

const Section = styled.section`
  margin-bottom: 10em;
  @media screen and (max-width: 460px) {
    margin-bottom: 6em;
  }
`

const Wrapper = styled.div`
  p {
    text-align: justify;
    text-justify: inter-ideograph;
    padding-left: 1em;
    padding-right: 2em;
    white-space: pre-wrap;
    @media screen and (max-width: 460px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const SymposiumWrapper = styled.div`
  img {
    @media screen and (max-width: 460px) {
      max-height: none;
    }
  }
`

const VideoWrapper = styled.div`
  width: 100%;
  padding-right: 2em;
  @media screen and (max-width: 460px) {
    padding-right: 0;
  }
`

const IframeWrapper = styled.div`
	position:relative;
	width:100%;
	height:0;
	padding-top:56.25%;
`

const Iframe = styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`

const BottomMargin = styled(Margin)`
  margin-bottom: 6em;
`

const SymposiumTemplate = () => {
  const data = useStaticQuery(graphql`
    query SymposiumQuery {
      allContentfulText(filter: {type: {eq: "symposium"}}, sort: {order: ASC, fields: order}) {
        edges {
          node {
            title
            body {
              json
            }
          }
        }
      }
      allContentfulSymposiumVideo(sort: {fields: order, order: ASC}) {
        group(field: times) {
          edges {
            node {
              title
              url {
                url
              }
            }
          }
        }
      }
      allFeedTestNote(filter: {link: {in: ["https://note.com/inunosenakaza/n/ne167b5493f3d"]}}) {
      # allFeedTestNote {
        edges {
          node {
            title
            contentSnippet
            isoDate
            link
            # localImage {
            #   childImageSharp {
            #     fluid(maxWidth: 1800) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            #   name
            # }
            media {
              thumbnail
            }
          }
        }
      }
    }
  `)
  const firstSympTexts = data.allContentfulText.edges
  const firstSympVideos = data.allContentfulSymposiumVideo.group[0].edges
  const secondSympVideos = data.allContentfulSymposiumVideo.group[1].edges
  const symposium1 = []
  data.allFeedTestNote.edges.map(({ node }) => {
    switch (node.link) {
      case SYMPOSIUM1[0]:
        symposium1[0] = { node }
        break;
      case SYMPOSIUM1[1]:
        symposium1[1] = { node }
        break;
      case SYMPOSIUM1[2]:
        symposium1[2] = { node }
        break;
      case SYMPOSIUM1[3]:
        symposium1[3] = { node }
        break;
      case SYMPOSIUM1[4]:
        symposium1[4] = { node }
        break;
      case SYMPOSIUM1[5]:
        symposium1[5] = { node }
        break;
      case SYMPOSIUM1[6]:
        symposium1[6] = { node }
        break;
      case SYMPOSIUM1[7]:
        symposium1[7] = { node }
        break;
    }
  })
  const seoDesc = "「クバへ／クバから」では、複数回開催するレクチャーやシンポジウム、オンラインワークショップを、一括して「座談会」と呼称し、実施していきます。" + " " + data.allContentfulText.edges[0].node.title + " | " + data.allContentfulText.edges[0].node.body.json.content[0].content[0].value + data.allContentfulText.edges[0].node.body.json.content[1].content[0].value
  return (
    <>
      <SEO title="symposium" description={seoDesc} />
      <Heading>symposium</Heading>
      <Margin />
      <BodyWrapper>
        <p>「クバへ／クバから」では、複数回開催するレクチャーやシンポジウム、オンラインワークショップを、一括して「座談会」と呼称し、実施していきます。</p>
        <MiddleMargin />
        <InternalLink to="/app/synopsis/#「座談会」の収録・公開">
          <Subheading>[synopsis]「座談会」の収録・公開 →</Subheading>
        </InternalLink>
        <Margin />
        <InfoBox>
          <div>
            <Subheading>{firstSympTexts[0].node.title}</Subheading>
            <LowMargin />
            {documentToReactComponents(firstSympTexts[0].node.body.json, options)}
          </div>
          <Margin />
          <div>
            <Subheading>{firstSympTexts[1].node.title}</Subheading>
            <LowMargin />
            {documentToReactComponents(firstSympTexts[1].node.body.json, options)}
          </div>
        </InfoBox>
      </BodyWrapper>
      <Section>
        <Wrapper>
          <div>
            <MiddleHeading id="第1回「三野新の作歴とプロジェクト全体の基本構想をめぐって」">第1回「三野新の作歴とプロジェクト全体の基本構想をめぐって」</MiddleHeading>
            <Margin />
            <BodyWrapper>
              <InfoBox>
                <div>
                  <Subheading>{firstSympTexts[2].node.title}</Subheading>
                  <LowMargin />
                  {documentToReactComponents(firstSympTexts[2].node.body.json, options)}
                </div>
              </InfoBox>
            </BodyWrapper>
            <SubMiddleHeading>映像アーカイヴ</SubMiddleHeading>
            {firstSympVideos.map(({ node }) => (
              <VideoWrapper>
                <Subheading>{node.title}</Subheading>
                <MiddleMargin />
                <IframeWrapper>
                  <Iframe src={node.url.url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
                </IframeWrapper>
                <BottomMargin />
              </VideoWrapper>
            ))}
          </div>
        </Wrapper>
        <Margin />
        <SubMiddleHeading style={{ marginTop: '6em' }}>テキストアーカイヴ</SubMiddleHeading>
        <LowMargin />
        <SymposiumWrapper>
          {symposium1.map(({ node }) => (
            <>
              <NoteCard item={node} />
              <Margin />
            </>
          ))}
        </SymposiumWrapper>
      </Section>
      <Section>
        <MiddleHeading id="第2回「「クバへ／クバから」の写真群と沖縄写真史をめぐって」">第2回「「クバへ／クバから」の写真群と沖縄写真史をめぐって」</MiddleHeading>
        <Margin />
        <BodyWrapper>
          <InfoBox style={{ marginBottom: 0 }}>
            <div>
              <Subheading>{firstSympTexts[4].node.title}</Subheading>
              <LowMargin />
              {documentToReactComponents(firstSympTexts[4].node.body.json, options)}
            </div>
          </InfoBox>
        </BodyWrapper>
        <Margin />
        {secondSympVideos.map(({ node }) => (
          <VideoWrapper>
            <Subheading>{node.title}</Subheading>
            <MiddleMargin />
            <IframeWrapper>
              <Iframe src={node.url.url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
            </IframeWrapper>
            <BottomMargin />
          </VideoWrapper>
        ))}
      </Section>
      <Margin />
      <Section>
        <MiddleHeading id="第3回「いぬのせなか座による応答」（仮）">第3回「いぬのせなか座による応答」（仮）</MiddleHeading>
        <BodyWrapper>
          <InfoBox style={{ marginBottom: 0 }}>
            <div>
              <Subheading>{firstSympTexts[5].node.title}</Subheading>
              <LowMargin />
              {documentToReactComponents(firstSympTexts[5].node.body.json, options)}
            </div>
          </InfoBox>
        </BodyWrapper>
      </Section>
      <Margin />
      <Section>
        <MiddleHeading id="第4回・第5回「オンラインワークショップ」（仮）">第4回・第5回「オンラインワークショップ」（仮）</MiddleHeading>
        <BodyWrapper>
          <InfoBox style={{ marginBottom: 0 }}>
            <div>
              <Subheading>{firstSympTexts[6].node.title}</Subheading>
              <LowMargin />
              {documentToReactComponents(firstSympTexts[6].node.body.json, options)}
            </div>
          </InfoBox>
        </BodyWrapper>
      </Section>
    </>
  )
}

export default SymposiumTemplate
