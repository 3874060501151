import React from "react";
import styled from '@emotion/styled'
import LowMargin from "./lowMargin";

const H2 = styled(props => <p {...props} />)`
  transform: scale(0.9,1.2);
  font-size: 1.5em;
  text-align: left;
  margin-bottom: 1.5em;
  margin-left: -2.2em;
  border-bottom: 1px solid ${props => props.theme.colors.text};
  @media screen and (max-width: 460px) {
    margin-left: -.8em;
  }
`

const MiddleHeading = (props) => {
  return (
    <>
      <LowMargin />
      <H2 {...props}>{props.children}</H2>
      <LowMargin />
    </>
  )
}

export default MiddleHeading
