import React from 'react'
import styled from '@emotion/styled'

const Div = styled.div`
  margin-bottom: 3em;
  content: '';
`

const Margin = () => (
  <Div />
)

export default Margin
