import React, { useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Heading from "../components/heading"
import { BodyWrapper } from "../components/bodyWrapper"
import MiddleHeading from "../components/middleHeading"
import Subheading from "../components/subheading"
import styled from "@emotion/styled"
import { options } from "../styles/richTextOptions"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LowMargin from "../components/lowMargin"
import SPBreak from "../components/SPBreak"
import { handleLogin } from "../services/auth"
import { addRole } from "../services/firestore"
import RoleBox from "../components/roleBox"
import MoreButton from "../components/moreButton"
import SEO from "../components/SEO"

const Wrapper = styled.div`
  width: 95%;
  text-align: right;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`

const MarginBottomWrapper = styled.div`
  padding-bottom: 9em;
  @media screen and (max-width: 460px) {
    padding-bottom: 6em;
  }
`

const SynopsisTemplate = () => {
  const data = useStaticQuery(graphql`
    query SynopsisQuery {
      allContentfulSynopsis(sort: {fields: order, order: ASC}) {
        edges {
          node {
            author
            editor
            body {
              json
            }
            title
            order
          }
        }
      }
    }
  `)
  // state
  const [role, setRole] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isRoled, setIsRoled] = useState(false)
  const [clickedId, setClickedId] = useState("")

  // 変数を見て、更新するstateを判断
  const handleSubmit = (event) => {
    event.preventDefault()
    addRole(role)
    handleLogin(role)
    setIsRoled(true)
    setIsOpen(false)
    navigate("/app/synopsis/#" + clickedId)
  }

  const handleChange = event => {
    setRole(event.target.value)
  }

  const handleClick = (event) => {
    event.preventDefault()
    setClickedId(event.target.id)
    setIsOpen(true)
  }

  const seoDesc = data.allContentfulSynopsis.edges[0].node.title + " | " + data.allContentfulSynopsis.edges[0].node.author + " " + data.allContentfulSynopsis.edges[0].node.editor + " " + data.allContentfulSynopsis.edges[0].node.body.json.content[0].content[0].value
  return (
    <>
      <SEO title="synopsis" description={seoDesc} />
      <Heading>synopsis</Heading>
      <BodyWrapper>
        {data.allContentfulSynopsis.edges.map(({ node }) => (
          <MarginBottomWrapper>
            <MiddleHeading id={node.title}>
              {node.title}
            </MiddleHeading>
            <Wrapper>
              <Subheading style={{ marginRight: '.5em' }}>{node.author}<SPBreak />{"　"}{node.editor}</Subheading>
            </Wrapper>
            <LowMargin />
            <RoleBox
              isRoled={isRoled}
              isOpen={isOpen}
            >
              {documentToReactComponents(node.body.json, options)}
              <MoreButton
                clickedId={node.title}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleClick={handleClick}
              />
            </RoleBox>
          </MarginBottomWrapper>
        ))}
      </BodyWrapper>
    </>
  )
}

export default SynopsisTemplate
