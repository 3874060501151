import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import FlexBox from "./flexBox"
import { format } from '../services/utils'
import { Link } from "gatsby"

const IndexLine = styled.div`
  content: '';
  display: block;
  width: 100%;
  height: 2em;
  position: relative;
  max-width: 400px;
`

const Title = styled.p`
  font-size: 0.9em;
  padding: 1em 0 0;
`

const Date = styled.p`
  font-size: 0.7em;
  position: absolute;
  bottom: 0.5em;
`

const Right = styled.div`
  width: 500px;
  max-height: 13em;
  overflow: hidden;
  position: relative;
  margin-top: -0.5em;
  /* a {
    color: ${props => props.theme.colors.highlight};
  } */
`


const AnnouncementCard = (props) => (
  <>
    <IndexLine><Date>{format(props.item.isoDate)}</Date></IndexLine>
    <FlexBox>
      {props.item.image ? (
        <Img
          fluid={props.item.image.fluid}
          alt={props.item.image.title}
          style={{ width: 200, marginRight: '1em' }}
        />
      ) : null}
      <Right>
        {props.item.link ? (
          props.item.link.link.substring(0, 24) === "https://to-from-kuba.com" ?
            <Link to={props.item.link.link.substring(24)}><Title>{props.item.title} <span style={{ color: '#b88fb6' }}>→</span></Title></Link>
            : <a href={props.item.link.link} target="_blank" ><Title>{props.item.title} <span style={{ color: '#b88fb6' }}>→</span></Title></a>
        ) : (
            <Title>{props.item.title}</Title>
          )}
      </Right>
    </FlexBox>
  </>
)

export default AnnouncementCard
