import React from "react"
import styled from '@emotion/styled'

const Section = styled.div`
  position: relative;
  padding-top: 3em;
`

const Line = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: ${props => props.theme.colors.text};
  width: 10px;
`

const Text = styled(props => <p {...props} />)`
  position: absolute;
  display: inline-block;
  top: 96px;
  left: -62px;
  transform: rotate(-90deg) scale(1.6, 1.2);
`

const HistoryContainer = (props) => (
  <>
    <Section>
      {props.children}
      <Line />
      <Text id="プロジェクト上演タイムライン">timeline →→→</Text>
    </Section>
  </>
)

export default HistoryContainer
