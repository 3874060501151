import React from "react"
import { getRoles } from "../services/firestore";
import MiddleHeading from "./middleHeading";
import styled from '@emotion/styled'
import Margin from "./margin"

const U = styled.u`
  display: inline;
  transform: scale(0.9,1.2);
  text-decoration: none;
  line-height: 2.5em;
  @media screen and (max-width: 460px) {
    padding-right: 0;
  }
`

const Wrapper = styled.div`
  text-align: justify;
  text-justify: inter-ideograph;
`

const Roles = () => {
  const roles = getRoles()
  return (
    <>
      <MiddleHeading id="入力された「役割」たち">入力された「役割」たち</MiddleHeading>
      <Wrapper>
        {roles
          // 開発用の役割「ディベロッパー」を削除
          .filter(value => value.role !== "ディベロッパー")
          .filter(value => value.role !== "開発用テスト")
          .map((role, i) => (
            <>
              {i == 0 ? (<U>{role.role}</U>) : (<U>{" "}▼{" "}{role.role}{" "}</U>)}
            </>
          ))}
      </Wrapper>
      <Margin />
    </>
  )
}

export default Roles
