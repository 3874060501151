import React from "react"
import { navigate, useStaticQuery } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import { addRole } from "../services/firestore"
import Margin from "./margin"
import styled from '@emotion/styled'
import TopImage from "./topImge"
import { BodyWrapper } from "./bodyWrapper"
import Subheading from "./subheading"
import LowMargin from "./lowMargin"
import SEO from "./SEO"

const Wrapper = styled.div`
  position: relative;
`

const Text = styled.p`
  position: absolute;
  display: inline-block;
  top: 107px;
  left: -100px;
  font-size: 2em;
  transform: rotate(-90deg) scale(1.6, 1.2);
  @media screen and (max-width: 460px) {
    font-size: 1em;
    top: 55px;
    left: -50px;
  }
`

const Paragraph = styled.p`
  line-height: 1.85em;
`

const FormContainer = styled.div`
  margin: 2em 0 4em;
  @media screen and (max-width: 460px) {
    padding: 0 1em 2em;
  }
`

const Form = styled.form`
  padding-left: 1em;
  @media screen and (max-width: 460px) {
    padding-left:0;
  }
`

const Input = styled.input`
  color: ${props => props.theme.colors.text};
  border-bottom: solid 1px ${props => props.theme.colors.text};
  padding-left: 1em;
  margin-left: 1em;
  width: 90%;
  &:focus{
    color: ${props => props.theme.colors.text};
    background-color: transparent;
  }
`

const Button = styled.input`
  border-bottom: solid;
  color: #b88fb6;
  font-weight: 600;
  margin-top: 2em;
`

const Remark = styled.p`
  padding-top: 2em;
  font-size: 0.8em;
`

class Login extends React.Component {

  state = {
    role: ``
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    addRole(this.state.role)
    handleLogin(this.state.role)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/app/index`)
    }

    return (
      <>
        <SEO description="このウェブサイトにアクセスするとき、あなたが思う、あなた自身が演じる「役割」を入力してください。（例：社会人、学生、読者、観客、造語、ニックネームなど） 送信された「役割」は公開されます。公開された「役割」から個人が特定されることはありません。一定時間が経過すると、こちらの画面に戻ります。その際には、再度「役割」を入力してください。" />
        <Wrapper>
          <TopImage />
          <Text>←←← scroll</Text>
          <FormContainer>
            <BodyWrapper>
              <Paragraph>
                このウェブサイトにアクセスするとき、あなたが思う、あなた自身が演じる「役割」を入力してください。<br />（例：社会人、学生、読者、観客、造語、ニックネームなど）
            </Paragraph>
              <Margin />
              <Form
                method="post"
                onSubmit={event => {
                  this.handleSubmit(event)
                  navigate(`/app/index`)
                }}
              >
                <Subheading>役割</Subheading>
                <Input type="text" name="role" onChange={this.handleUpdate} />
                <LowMargin />
                <Remark>
                  送信された「役割」は公開されます。公開された「役割」から個人が特定されることはありません。<br />
                一定時間が経過すると、こちらの画面に戻ります。その際には、再度「役割」を入力してください。
              </Remark>
                <Margin />
                <Subheading>
                  <Button type="submit" value="送信 →" />
                </Subheading>
              </Form>
            </BodyWrapper>
          </FormContainer>
        </Wrapper>
      </>
    )
  }
}

export default Login
