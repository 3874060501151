import { useState, useEffect } from "react"
import firebase from "../adapter/firebase";

export const addRole = role => {
  firebase
    .firestore()
    .collection("roles")
    .add({ role })
}

export const getRoles = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("roles")
      .onSnapshot(snapshot => {
        const roles = []
        snapshot.forEach(d => roles.push({
          id: d.id,
          ...d.data(),
        }));
        setRoles(roles)
      });
    return () => unsubscribe();
  }, []);

  return roles;
}
