import React from "react";
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"
import SPBreak from "../components/SPBreak"
import Margin from "../components/margin"
import MiddleHeading from "./middleHeading";

const TokyoGrant = styled.p`
  font-size: 0.8em;
  text-indent: initial;
`

const KyotoGrant = styled.p`
  font-size: 0.8em;
  text-indent: initial;
`

const GrantMargin = styled.div`
  content: '';
  margin: 0.5em;
`

const Grant = () => {
  const data = useStaticQuery(graphql`
    query GrantQeury {
      allContentfulAsset(filter: {title: {in: ["artscounciltokyo", "grant_logo_kyoto"]}}) {
        edges {
          node {
            fixed(height: 52) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            title
          }
        }
      }
    }
  `)
  const grant_logo_kyoto = data.allContentfulAsset.edges.filter(value => value.node.title === "grant_logo_kyoto")[0].node
  const grant_logo_tokyo = data.allContentfulAsset.edges.filter(value => value.node.title === "artscounciltokyo")[0].node
  return (
    <>
      <MiddleHeading>助成</MiddleHeading>
      <TokyoGrant >
        公益財団法人東京都歴史文化財団　<SPBreak />アーツカウンシル東京
        </TokyoGrant>
      <GrantMargin />
      <KyotoGrant> 新型コロナウイルス感染症の影響に伴う<SPBreak />京都市⽂化芸術活動緊急奨励⾦</KyotoGrant><br />
      <Img fixed={grant_logo_tokyo.fixed} alt={grant_logo_tokyo.title} style={{ maxHeight: 52, margin: '1em 1em 0 0' }} />
      <Img fixed={grant_logo_kyoto.fixed} alt={grant_logo_kyoto.title} style={{ maxHeight: 52 }} />
      {" "}<br />
      <Margin />
    </>
  )
}

export default Grant
