import React from "react";
import styled from "@emotion/styled"

const Div = styled(props => <div {...props} />)`
  position: relative;
  margin-bottom: 6em;
  padding: 1.5em 1em 2em 1em;
  background-color: #1f2721;
  p {font-size: 0.9em};
`

const InfoBox = (props) => (
  <Div {...props}>{props.children}</Div>
)

export default InfoBox
