import React from "react"
import styled from "@emotion/styled"

const _SubMiddleHeading = styled(props => <p {...props} />)`
  transform: scale(0.9,1.2);
  font-size: 1.5em;
  text-align: left;
  margin-bottom: 1.5em;
  margin-left: -2.7em;
  @media screen and (max-width: 460px) {
    margin-left: -.8em;
  }
`

const SubMiddleHeading = (props) => (
  <_SubMiddleHeading {...props}>{props.children}</_SubMiddleHeading>
)

export default SubMiddleHeading
