import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const TopImage = () => {
  const data = useStaticQuery(graphql`
    query TopImageQuery {
      contentfulAsset(title: {eq: "top_image"}) {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `)
  return (
    < Img
      fluid={data.contentfulAsset.fluid}
      alt={data.contentfulAsset.title}
      style={{ marginTop: '0.5em' }}
    />
  )
}

export default TopImage
