import React from "react";
import styled from '@emotion/styled'

const H1 = styled.h1`
  font-size: 3em;
  position: relative;
  display: inline-block;
  margin-bottom: .5em;
  &:after {
    position: absolute;
    bottom: .2em;
    right: -.1em;
    width: 1.5em;
    height: .5em;
    content: '';
    backdrop-filter: blur(3.7px);
    transform: rotate(40deg);
  }
`

const Heading = ({ children }) => {
  return (
    <H1>{children}</H1>
  )
}

export default Heading
