import React from "react"
import styled from "@emotion/styled"

const Base = styled.div`
  overflow: hidden;
  position: relative;
`

const Rolebox = styled(Base)`
  max-height: ${props => props.isRoled ? 'inherit' : '200px'};
  section { display: ${props => (props.isRoled && !props.isOpen) ? 'none' : 'block'} }
  @media screen and (max-width: 460px) {
    max-height: ${props => props.isRoled ? 'inherit' : '250px'};
  }
`

const RoleBox = (props) => (
  <Rolebox isRoled={props.isRoled} isOpen={props.isOpen}>{props.children}</Rolebox>
)

export default RoleBox
