import React from "react"
import styled from "@emotion/styled"

const Break = styled.br`
  display: none;
  @media screen and (max-width: 460px) {
    display: block;
  }
`

const SPBreak = () => (
  <Break />
)

export default SPBreak
