import React from "react";
import styled from '@emotion/styled'

const H3 = styled(props => <h3 {...props} />)`
  font-size: 1em;
  display: inline-block;
  margin-bottom: .4em;
  font-weight: bold;
  transform: scale(1,1.4);
`

const Subheading = (props) => {
  return (
    <H3 {...props}>{props.children}</H3>
  )
}

export default Subheading
