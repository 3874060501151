import React from "react"
import { Router, Redirect } from "@reach/router"
import Layout from "../components/Layout";
import PrivateRoute from "../components/privateRoute"
import AboutTemplate from "../templates/about"
import ScheduleTemplate from "../templates/schedule"
import StatementTemplate from "../templates/statement"
import SynopsisTemplate from "../templates/synopsis"
import Login from "../components/login"
import Container from "../components/Container";
import IndexTemplate from "../templates/index";
import SymposiumTemplate from "../templates/symposium";
import PhotobookTemplate from "../templates/photobook";
import ExhibitionTemplate from "../templates/exhibition";

const App = () => {
  return (
    <Layout>
      <Container>
        <Router>
          <PrivateRoute path="/app/about" component={AboutTemplate} />
          <PrivateRoute path="/app/schedule" component={ScheduleTemplate} />
          <PrivateRoute path="/app/statement" component={StatementTemplate} />
          <PrivateRoute path="/app/synopsis" component={SynopsisTemplate} />
          <PrivateRoute path="/app/symposium" component={SymposiumTemplate} />
          <PrivateRoute path="/app/photobook" component={PhotobookTemplate} />
          <PrivateRoute path="/app/exhibition" component={ExhibitionTemplate} />
          <PrivateRoute path="/app/index" component={IndexTemplate} />
          <Login path="/app/login" />
          <Redirect noThrow from="/app/" to="/app/login" />
        </Router>
      </Container>
    </Layout>
  )
}
export default App
