import React from "react"
import styled from "@emotion/styled"

const Link = styled.a`
  color: #b88fb6;
`

export const ExternalLink = (props) => (
  <Link
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {props.children}
  </Link>
)
