import React from "react";
import Subheading from "../components/subheading";
import Margin from "../components/margin"
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Link } from "gatsby";


export const options = {
  renderNode: {
    [BLOCKS.HEADING_3]: (node, children) => (
      <>
        <Margin /><Subheading id={children}>{children}</Subheading>
      </>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      const baseUrl = node.data.uri.substring(0, 24)
      const path = node.data.uri.substring(24)
      return (
        <>
          {(baseUrl == "https://to-from-kuba.com") ?
            <Link to={path}><span style={{ color: '#b88fb6' }}>{children}</span></Link>
            : <a href={node.data.uri} target="_blank" ><span style={{ color: '#b88fb6' }}>{children}</span></a>
          }
        </>
      )
    }
  },
}
