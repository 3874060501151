import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Logo from './Logo/logo.svg'
import FlexBox from './flexBox'
import { format, replacePunctuation } from '../services/utils'

const Title = styled.p`
  font-size: 1.3em;
  @media screen and (max-width: 460px) {
    font-size: 0.95em;
  }
`

const Body = styled.p`
  display: inline-block;
  font-size: 0.8em;
  padding-top: 1.7em;
  position: relative;
  &:after {
    position: absolute;
    bottom: -3em;
    right: -.6em;
    width: 100%;
    height: 4.5em;
    content: '';
    backdrop-filter: blur(3.7px);
    transform: rotate(-12deg);
  }
  @media screen and (max-width: 460px) {
    padding-top: 0.5Rem;
    &:after {
      display: none;
    }
  }
`

const Date = styled.p`
  font-size: 0.7em;
  position: absolute;
  left: .5em;
  bottom: 0.5em;
`

const Div = styled.div`
  @media screen and (max-width: 460px) {
    max-height: 172px;
  }
`

const Image = styled(props => <img {...props} />)`
  max-width: 400px;
  max-height: 210px;
  @media screen and (max-width: 460px) {
    max-height: 135px;
  }
`

const IndexLine = styled.div`
  content: '';
  display: block;
  width: 100%;
  height: 2em;
  background-color: #41C9B4;
  position: relative;
  max-width: 400px;
`

const Right = styled.div`
  width: 500px;
  max-height: 13em;
  overflow: hidden;
  position: relative;
  padding: 0 0 0 1em;
  margin-top: -0.5em;
  &:after {
    position: absolute;
    bottom: -3em;
    right: -.6em;
    width: 100%;
    height: 4.5em;
    content: '';
    backdrop-filter: blur(3.7px);
    transform: rotate(-12deg);
  }
  @media screen and (max-width: 460px) {
    max-height: 6em;
    padding: 0.4em 0 0;
    margin-top: 0;
    &:after {
      bottom: -1.5em;
      right: -1.1em;
      height: 2.7em;
    }
  }
`

const More = styled.p`
  font-size: 0.5em;
  display: inline-block;
  position: absolute;
  right: 3em;
  transform: scale(1.6, 1.2) rotate(-12deg);
  bottom: .7em;
  z-index: 2;
  color: white;
`

const NoteCard = (props) => {
  const data = useStaticQuery(graphql`
    query NoteCardQuery {
      contentfulAsset(title: {eq: "banner"}) {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `)
  const body = replacePunctuation(props.item.contentSnippet)
  const date = format(props.item.isoDate)
  return (
    <>
      <a href={props.item.link} target="_blank">
        <IndexLine>
          <img
            src={Logo}
            style={{ width: '8em', position: 'absolute', top: '-1.2em', right: '-1.5em', zIndex: 2 }}
            alt="note_logo"
          />
          <Date>{date}</Date>
        </IndexLine>
        <FlexBox>
          <Div>
            {props.item.media ? (
              <Image
                src={props.item.media.thumbnail}
                alt={props.item.title}
              />
            ) : (
                <Img
                  fluid={data.contentfulAsset.fluid}
                  alt={data.contentfulAsset.title}
                  style={{ width: 400 }}
                />
              )}
          </Div>
          <Right>
            <Title>{props.item.title}</Title>
            <Body>{body}</Body>
            <More>more →</More>
          </Right>
        </FlexBox>
      </a>
    </>
  )
}

export default NoteCard
