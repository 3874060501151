import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  margin-bottom: 5em;
  margin-left: 3em;
  position: relative;
  display: inline-block;
  padding-right: 1.5em;
  &:before {
    position: absolute;
    top: -1px;
    left: -3em;
    content: '';
    width: calc(100% + 3em);
    height: 1px;
    background-color: ${props => props.theme.colors.text};
  }
  @media screen and (max-width: 460px) {
    display: block;
  }
`

const CardContainer = (props) => (
  <>
    <Container>
      {props.children}
    </Container>
  </>
)

export default CardContainer
