import firebase from "firebase/app"
import 'firebase/firestore'

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyBa4ZcsyOkcGWacmL0fwFaZBmmCmStBbP0",
  authDomain: "to-from-kuba.firebaseapp.com",
  databaseURL: "https://to-from-kuba.firebaseio.com",
  projectId: "to-from-kuba",
  storageBucket: "to-from-kuba.appspot.com",
  messagingSenderId: "868975960081",
  appId: "1:868975960081:web:5ea1104952e8438a0211b0",
  measurementId: "G-54E2FD4KFR"
});

export default firebase
