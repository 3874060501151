import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Roles from "../components/roles";
import Heading from "../components/heading"
import MiddleHeading from "../components/middleHeading"
import Subheading from "../components/subheading"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from "@emotion/styled"
import Grant from "../components/grant"
import Margin from "../components/margin";
import LowMargin from "../components/lowMargin";
import { ExternalLink } from "../components/externalLink";
import { BodyWrapper } from "../components/bodyWrapper";
import { options } from "../styles/richTextOptions";
import MiddleMargin from "../components/middleMargin";
import SubMiddleHeading from "../components/subMiddleHeading";
import SEO from "../components/SEO";

const Profile = styled.p`
  margin-bottom: 3em;
`

const Link = styled(ExternalLink)`
  color: #b88fb6;
  p {
    transform: scale(1,1.4);
  }
`

// const SubMiddleHeading = styled.p`
//   transform: scale(0.9,1.2);
//   font-size: 1.5em;
//   text-align: left;
//   margin-bottom: 1.5em;
//   margin-left: -2.7em;
//   @media screen and (max-width: 460px) {
//     margin-left: -.8em;
//   }
// `

const Markdown = styled(props => <div {...props} />)`
  a{  color: #b88fb6; }
`

const AboutTemplate = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allContentfulProfile(filter: {type: {in: ["committee", "support", "accompany"]}},sort: {fields: order, order: ASC}) {
        edges {
          node {
            name
            nameEn
            twitter {
              twitter
            }
            website {
              website
            }
            profile {
              childMarkdownRemark {
                html
              }
            }
            type
            supporterJob
          }
        }
      }
      allContentfulParagraph(sort: {fields: order, order: ASC}) {
        edges {
          node {
            title
            author
            body {
              json
            }
          }
        }
      }
    }
  `)
  const committees = data.allContentfulProfile.edges.filter(value => value.node.type == "committee")
  const supports = data.allContentfulProfile.edges.filter(value => value.node.type == "support")
  const accompanies = data.allContentfulProfile.edges.filter(value => value.node.type == "accompany")
  return (
    <>
      <SEO title="about" />
      <Heading>about</Heading>
      <BodyWrapper>
        {data.allContentfulParagraph.edges.map(({ node }) => (
          <>
            <MiddleHeading id={node.title}>{node.title}</MiddleHeading>
            {documentToReactComponents(node.body.json, options)}
            <Margin />
          </>
        ))}
        <MiddleHeading id="実行委員会">実行委員会</MiddleHeading>
        {committees.map(({ node }) => (
          <>
            <Subheading id={node.name}>{node.name}&emsp;{node.nameEn ? node.nameEn : null}</Subheading>
            <Profile>
              {node.profile ? <Markdown dangerouslySetInnerHTML={{ __html: node.profile.childMarkdownRemark.html }} /> : null}<br />
              {node.website && (
                <Link
                  href={node.website.website}
                >
                  <p>website</p>
                </Link>
              )}
              {node.twitter && (
                <Link
                  href={node.twitter.twitter}
                >
                  <p>twitter</p>
                </Link>
              )}
            </Profile>
            <MiddleMargin />
          </>
        ))}
        <LowMargin />
        <SubMiddleHeading id="サポートメンバー">サポートメンバー</SubMiddleHeading>
        <LowMargin />
        {supports.map(({ node }) => (
          <>
            <Subheading>{node.name}&emsp;{node.nameEn ? node.nameEn : null}</Subheading>
            <Profile>
              （{node.supporterJob}）<br />
              {node.profile ? node.profile.profile : null}<br />
              <LowMargin />
              {node.twitter && (
                <li>
                  <Link
                    href={node.twitter.twitter}
                  >
                    <p>twitter</p>
                  </Link>
                </li>
              )}
            </Profile>
          </>
        ))}
        <SubMiddleHeading id="伴走者">伴走者</SubMiddleHeading>
        <LowMargin />
        {accompanies.map(({ node }) => (
          <>
            <Subheading>{node.name}&emsp;{node.nameEn ? node.nameEn : null}</Subheading>
            <Profile>
              {node.profile ? <Markdown dangerouslySetInnerHTML={{ __html: node.profile.childMarkdownRemark.html }} /> : null}<br />
              <LowMargin />
              {node.twitter && (
                <li>
                  <Link
                    href={node.twitter.twitter}
                  >
                    <p>twitter</p>
                  </Link>
                </li>
              )}
            </Profile>
          </>
        ))}
        <LowMargin />
        <SubMiddleHeading id="パフォーマー（役名未定）">パフォーマー（役名未定）</SubMiddleHeading>
        {" "}<br />
        <LowMargin />
        <Roles />
        <Grant />
      </BodyWrapper>
    </>
  )
}

export default AboutTemplate
