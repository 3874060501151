import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const _InternalLink = styled(props => <Link {...props} />)`
  color: #b88fb6;
`

const InternalLink = (props) => (
  <_InternalLink {...props}>{props.children}</_InternalLink>
)

export default InternalLink
