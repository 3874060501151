// partナンバー + 1 が最後尾の数字
const SYMPOSIUM1_0 = "https://note.com/inunosenakaza/n/n79665cf8f949"
const SYMPOSIUM1_1 = "https://note.com/inunosenakaza/n/ne167b5493f3d"
const SYMPOSIUM1_2 = "https://note.com/inunosenakaza/n/n801b17319e36"
const SYMPOSIUM1_3 = "https://note.com/inunosenakaza/n/n35a89e3d55e0"
const SYMPOSIUM1_4 = "https://note.com/inunosenakaza/n/nbd277319e83c"
const SYMPOSIUM1_5 = "https://note.com/inunosenakaza/n/nb90b2518c94f"
const SYMPOSIUM1_6 = "https://note.com/inunosenakaza/n/nc500d58bbaed"
const SYMPOSIUM1_7 = "https://note.com/inunosenakaza/n/n88413e7b253e"

export const SYMPOSIUM1 = [
  SYMPOSIUM1_0,
  SYMPOSIUM1_1,
  SYMPOSIUM1_2,
  SYMPOSIUM1_3,
  SYMPOSIUM1_4,
  SYMPOSIUM1_5,
  SYMPOSIUM1_6,
  SYMPOSIUM1_7,
]
