import React from 'react'
import styled from '@emotion/styled'

const Div = styled.div`
  margin-bottom: 2em;
  content: '';
`

const MiddleMargin = () => (
  <Div />
)

export default MiddleMargin
