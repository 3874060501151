import React from "react"
import styled from "@emotion/styled"

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: ${props => props.theme.responsive.large};
  @media screen and (max-width: 460px) {
    flex-flow:row wrap;
  }
`

const FlexBox = (props) => (
  <Flex>
    {props.children}
  </Flex>
)

export default FlexBox
