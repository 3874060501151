import React from 'react'
import styled from '@emotion/styled'

const Div = styled.div`
  margin-bottom: 1em;
  content: '';
`

const LowMargin = () => (
  <Div />
)

export default LowMargin
