import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../components/heading"
import { BodyWrapper } from "../components/bodyWrapper"
import MiddleHeading from "../components/middleHeading"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { options } from "../styles/richTextOptions"
import Subheading from "../components/subheading"
import Margin from "../components/margin"
import InternalLink from "../components/internalLink"
import SEO from "../components/SEO"

const ExhibitionTemplate = () => {
  const data = useStaticQuery(graphql`
    query ExhibitionQuery {
      contentfulExhibition {
        title
        description {
          json
        }
      }
    }
  `)
  const exhibition = data.contentfulExhibition
  const seoDesc = exhibition.title + " | " + exhibition.description.json.content[0].content[0].value
  return (
    <>
      <SEO title="exhibition" description={seoDesc} />
      <Heading>exhibition</Heading>
      <BodyWrapper>
        <MiddleHeading id={exhibition.title}>{exhibition.title}</MiddleHeading>
        {documentToReactComponents(exhibition.description.json, options)}
        <Margin />
        <InternalLink to="/app/synopsis/#展覧会の開催、パフォーマンスの実施"><Subheading>[synopsis]展覧会の開催、パフォーマンスの実施 →</Subheading></InternalLink>
      </BodyWrapper>
    </>
  )
}

export default ExhibitionTemplate
