import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../components/heading"
import { BodyWrapper } from "../components/bodyWrapper"
import MiddleHeading from "../components/middleHeading"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { options } from "../styles/richTextOptions"
import InfoBox from "../components/infoBox"
import Subheading from "../components/subheading"
import LowMargin from "../components/lowMargin"
import Margin from "../components/margin"
import SEO from "../components/SEO"

const PhotobookTemplate = () => {
  const data = useStaticQuery(graphql`
    query PhotobookQuery {
      contentfulPhotobook {
        title
        description {
          json
        }
        remarks {
          json
        }
      }
    }
  `)
  const photobook = data.contentfulPhotobook

  const seoDesc = photobook.title + " | " + photobook.description.json.content[0].content[0].value + " " + photobook.description.json.content[1].content[0].value + " " + photobook.description.json.content[2].content[0].value

  return (
    <>
      <SEO title="photo book" description={seoDesc} />
      <Heading>photo book</Heading>
      <BodyWrapper>
        <MiddleHeading id={photobook.title}>{photobook.title}</MiddleHeading>
        {documentToReactComponents(photobook.description.json, options)}
        <Margin />
        <InfoBox>
          <Subheading>{photobook.remarks.json.content[0].content[0].value}</Subheading>
          <LowMargin />
          {documentToReactComponents(photobook.remarks.json.content[1], options)}
          {documentToReactComponents(photobook.remarks.json.content[2], options)}
        </InfoBox>
      </BodyWrapper>
    </>
  )
}

export default PhotobookTemplate
