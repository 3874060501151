import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../components/heading"
import NoteCard from "../components/noteCard"
import AnnouncementCard from "../components/announcementCard"
import HistoryContainer from "../components/historyContainer"
import CardContainer from "../components/cardContainer"
import { SYMPOSIUM1 } from "../constants/noteUrl"
import SEO from "../components/SEO"

const ScheduleTemplate = () => {
  const data = useStaticQuery(graphql`
    query ScheduleQuery {
      # TODO タイトルのルールでfilterをかける。正規表現をつかう必要がありあそう
      allFeedTestNote {
        edges {
          node {
            title
            contentSnippet
            isoDate
            link
            media {
              thumbnail
            }
          }
        }
      }
      allContentfulAnnouncement {
        edges {
          node {
            date
            title
            link {
              link
            }
          }
        }
      }
    }
  `)
  const list = []
  // アイテムのtype
  const NOTE = "note"
  const CONTENTFUL = "contentful"

  // Contentfulの記事を整形してリストに追加
  data.allContentfulAnnouncement.edges.map(({ node }) => {
    const ob = { "isoDate": node.date + "T00:00:00.000Z", "title": node.title, "image": node.image ? node.image : null, "type": CONTENTFUL, "link": node.link }
    list.push(ob)
  })
  // noteのアイテムのうち、2020年10月10日投稿の記事を整列
  data.allFeedTestNote.edges.map(({ node }) => {
    node['type'] = NOTE
    switch (node.link) {
      case SYMPOSIUM1[0]:
        node.isoDate = "2020-10-10T12:00:00.000Z"
        break;
      case SYMPOSIUM1[1]:
        node.isoDate = "2020-10-10T12:00:10.000Z"
        break;
      case SYMPOSIUM1[2]:
        node.isoDate = "2020-10-10T12:00:20.000Z"
        break;
      case SYMPOSIUM1[3]:
        node.isoDate = "2020-10-10T12:00:30.000Z"
        break;
      case SYMPOSIUM1[4]:
        node.isoDate = "2020-10-10T12:00:40.000Z"
        break;
      case SYMPOSIUM1[5]:
        node.isoDate = "2020-10-10T12:00:50.000Z"
        break;
      case SYMPOSIUM1[6]:
        node.isoDate = "2020-10-10T12:01:00.000Z"
        break;
      case SYMPOSIUM1[7]:
        node.isoDate = "2020-10-10T12:01:10.000Z"
        break;
      default:
        break;
    }
    list.push(node)
  })

  // isoDate降順にソート
  list.sort(function (a, b) {
    if (a.isoDate > b.isoDate) return -1;
    if (a.isoDate < b.isoDate) return 1;
    return 0
  })

  return (
    <>
      <SEO title="schedule" description={`timeline →→→　▼${list[0].title}▼${list[0].title}`} />
      <Heading>schedule</Heading>
      <HistoryContainer>
        {list.map(value => (
          <CardContainer>
            {(value.type == CONTENTFUL) && (
              <AnnouncementCard item={value} />
            )}
            {(value.type == NOTE) && (
              <NoteCard item={value} />
            )}
          </CardContainer>
        ))}
      </HistoryContainer>
    </>
  )
}

export default ScheduleTemplate
