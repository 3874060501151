import React from "react"
import Margin from "./margin"
import styled from '@emotion/styled'
import Subheading from "./subheading"
import SPBreak from "./SPBreak"

const Paragraph = styled.p`
  line-height: 1.85em;
`

const BlurCover = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(6px);
`

const ClickBlurCover = styled(BlurCover)`
  display: ${props => props.isOpen ? 'none' : 'block'};
  z-index: 1;
  position: absolute;
  top: 124px;
  left: -7em;
  width: 60%;
  height: 7em;
  transform: rotate(145deg);
  @media screen and (max-width: 460px) {
    width: 100%;
    top:  138px;
    left: -3em;
  }
`

const FormContainer = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 400px;
`

const StyledForm = styled.form`
  padding-left: 1em;
  @media screen and (max-width: 460px) {
    padding-left:0;
  }
`

const Input = styled.input`
  color: ${props => props.theme.colors.text};
  border-bottom: solid 1px ${props => props.theme.colors.text};
  padding-left: 1em;
  margin-left: 1em;
  width: 90%;
  &:focus{
    color: ${props => props.theme.colors.text};
    background-color: transparent;
  }
`

const Button = styled.input`
  border-bottom: solid;
  color: #b88fb6;
  font-weight: 600;
  margin-top: 2em;
`

const ClickButton = styled(Button)`
  position: absolute;
  top: 165px;
  left: 0;
  margin-top: 0;
  z-index: 2;
  @media screen and (max-width: 460px) {
    top: 218px;
  }

`

const SideText = styled.aside`
  position: absolute;
  display: inline-block;
  top: 200px;
  left: -44px;
  color: #b88fb6;
  transform: rotate(-90deg) scale(2.9, 0.9) translateX(-50%);
  font-size: 1.4em;
  @media screen and (max-width: 460px) {
    top: 250px;
    left: -50px;
  }
`

const MoreButton = (props) => {

  // sectionの表示はroleBox.jsで制御
  return (
    <>
      <section>
        <ClickBlurCover isOpen={props.isOpen} />
        <ClickButton
          id={props.clickedId}
          onClick={
            event => {
              props.handleClick(event)
            }
          }
          type="submit"
          value="続きを読む"
        />
      </section>
      {/* TODO <SideText>← open ←</SideText> */}
      <BlurCover isOpen={props.isOpen} />
      <FormContainer isOpen={props.isOpen}>
        <Wrapper>
          <Paragraph>
            続きを読むには<SPBreak />「役割」の入力が必要です。
            </Paragraph>
          <Margin />
          <StyledForm
            method="post"
            onSubmit={event => {
              props.handleSubmit(event)
            }}
          >
            <Subheading>役割</Subheading>
            <Input type="text" name="role" onChange={(event) => props.handleChange(event)} />
            <Margin />
            <Subheading>
              <Button type="submit" value="送信 →" />
            </Subheading>
          </StyledForm>
        </Wrapper>
      </FormContainer>
    </>
  )
}

export default MoreButton
